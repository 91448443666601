<template>
  <div class="customer-service">
    <div v-if="isLoaded && showQuickButtons" class="quick-buttons">
      <button 
        v-for="(question, key) in commonQuestions" 
        :key="key"
        @click="sendCommonQuestion(key)"
        class="quick-button">
        {{ question.label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerService',
  data() {
    return {
      isLoaded: false,
      currentAgent: null,
      showQuickButtons: true,
      commonQuestions: {
        price: {
          label: '产品价格',
          text: '请问产品价格是多少？'
        },
        delivery: {
          label: '配送说明',
          text: '请问大概需要多久能收到货？'
        },
        payment: {
          label: '支付方式',
          text: '支持哪些支付方式？'
        },
        return: {
          label: '退换政策',
          text: '请问有退换货政策吗？'
        },
        service: {
          label: '售后服务',
          text: '想了解下售后服务内容'
        }
      }
    }
  },
  mounted() {
    // 等待DOM完全加载后再初始化
    window.addEventListener('load', () => {
      setTimeout(() => {
        this.initTawkTo();
      }, 2000);
    });
  },
  methods: {
    initTawkTo() {
      if (window.Tawk_API) return; // 防止重复初始化
      
      var Tawk_API = window.Tawk_API || {};
      var Tawk_LoadStart = new Date();
      
      (function(){
        var s1 = document.createElement("script");
        var s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/677966f8af5bfec1dbe691d6/1igp3kfcf';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
      })();

      Tawk_API.onLoad = () => {
        this.isLoaded = true;
        
        // 设置基础访客信息
        Tawk_API.setAttributes({
          'name': '访客'
        }, (error) => {
          if (error) console.warn('TawkTo setAttributes error:', error);
        });

        // 设置欢迎消息
        setTimeout(() => {
          try {
            Tawk_API.sendMessage('您好！请问有什么可以帮您？');
          } catch (error) {
            console.warn('TawkTo sendMessage error:', error);
          }
        }, 1000);

        // 调整位置和样式
        this.adjustTawkPosition();
      };

      // 配置聊天窗口
      Tawk_API.customStyle = {
        zIndex: 2147483647,  // 使用最大z-index值
        visibility : 'visible',
        opacity: 1,
        position: 'fixed',
        language: 'zh-cn'
      };

      // 监听窗口大小变化
      window.addEventListener('resize', this.adjustTawkPosition);
    },

    adjustTawkPosition() {
      const adjustStyles = () => {
        const iframes = document.querySelectorAll('iframe[title*="chat"]');
        iframes.forEach(iframe => {
          const container = iframe.parentElement;
          if (container) {
            container.style.cssText = `
              bottom: 80px !important;
              right: 20px !important;
              z-index: 2147483647 !important;
              visibility: visible !important;
              opacity: 1 !important;
              position: fixed !important;
            `;
          }
          iframe.style.cssText = `
            bottom: 80px !important;
            right: 20px !important;
            z-index: 2147483647 !important;
            visibility: visible !important;
            opacity: 1 !important;
            position: fixed !important;
          `;
        });

        const minButton = document.querySelector('.tawk-min-container');
        if (minButton) {
          minButton.style.cssText = `
            bottom: 80px !important;
            right: 20px !important;
            z-index: 2147483647 !important;
            visibility: visible !important;
            opacity: 1 !important;
            position: fixed !important;
          `;
        }
      };

      // 多次尝试调整位置，确保样式正确应用
      adjustStyles();
      setTimeout(adjustStyles, 1000);
      setTimeout(adjustStyles, 2000);
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.adjustTawkPosition);
      window.removeEventListener('load', this.initTawkTo);
    },

    sendCommonQuestion(key) {
      const question = this.commonQuestions[key];
      if (question && window.Tawk_API) {
        window.Tawk_API.sendMessage(question.text);
      }
    }
  }
}
</script>

<style scoped>
.customer-service {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 2147483647;
}

.quick-buttons {
  position: absolute;
  bottom: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.quick-button {
  padding: 8px 16px;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.quick-button:hover {
  background: #f5f5f5;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
</style> 