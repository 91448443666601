<template>
<section class="footer">
    <div class="footer-wrapper">
        <el-row :gutter="0" type="flex" align="top">
            <el-col :xs="24" :sm="12" :md="12" :lg="4" :xl="4">
                <div class="footer-item cus-text cus-flex-y cus-flex-start">
                    <el-image 
                        :src="require('@/assets/images/ipnova-logo.png')" 
                        fit="cover" 
                        style="width: 131px;height: 90px;"
                        :preview-src-list="[]">
                    </el-image>
                    <div class="cus-text-left cus-mt-8">{{ $t('footer.contactus') }}</div>
                    <div class="cus-text-left cus-mt-8">
                        <i class="el-icon-message"></i>
                        <span class="cus-mx-2">{{$t('footer.email')}}：</span>
                        <span>ipinsta@163.com</span>
                    </div>
                </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="5" :xl="5">
                <div class="footer-item">
                    <div class="article-box">
                        <div class="cus-h6 title">{{ $t('footer.product') }}</div>
                        <div class="cus-body article-item" @click="handleClick(articleItem.id)" v-for="(articleItem, articleIndex) in articleArr['product']" :key="articleIndex">
                            <span>{{ $t(articleItem.articleTitle) }}</span>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="5" :xl="5" v-if="!isMyPage">
                <div class="footer-item">
                    <div class="article-box">
                        <div class="cus-h6 title">{{ $t('footer.resourceService') }}</div>
                        <div class="cus-body article-item" @click="$router.push('/price')">
                            <span>{{ $t('footer.price') }}</span>
                        </div>
                        <div class="cus-body article-item" @click="handleClick('1863882012847374336')">
                            <span>{{ $t('footer.QA') }}</span>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="5" :xl="5">
                <div class="footer-item">
                    <div class="article-box">
                        <div class="cus-h6 title">{{ $t('footer.useCase') }}</div>
                        <div class="cus-body article-item" @click="handleClick(articleItem.id)" v-for="(articleItem, articleIndex) in articleArr['useCase']" :key="articleIndex">
                            <span>{{ $t(articleItem.articleTitle) }}</span>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="5" :xl="5">
                <div class="footer-item">
                    <div class="article-box">
                        <div class="cus-h6 title">{{ $t('footer.legalDisclaimer') }}</div>
                        <div class="cus-body article-item" @click="handleClick(articleItem.id)" v-for="(articleItem, articleIndex) in articleArr['legalDisclaimer']" :key="articleIndex">
                            <span>{{ $t(articleItem.articleTitle) }}</span>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <div class="box cus-caption cus-flex-x cus-flex-xy-center">
            <span class="cus-mr-5">@2024 广州因斯塔信息科技有限公司</span>
            <el-link type="info" href="https://beian.miit.gov.cn/#/Integrated/index" :underline="false">粤ICP备2024348909号-1</el-link>
        </div>
    </div>
</section>
</template>

<script>
import {
    articleArr
} from '@/common/data/footer'

export default {
    name: 'Footer',
    data() {
        return {
            articleArr
        }
    },
    computed: {
        isMyPage() {
            return this.$route.path.startsWith('/my')
        }
    },
    methods: {
        handleClick(id) {
            // 使用 window.location.href 进行跳转
            window.location.href = `/article?id=${id}`
        }
    }
}
</script>

<style lang="scss" scoped>
.footer {
    background: #222153;
    padding: 40px 0 0;
    width: 100%;
    min-width: 320px;
    position: relative;
    box-sizing: border-box;

    &-wrapper {
        max-width: 1480px;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        position: relative;

        /deep/ .el-row {
            border-bottom: 1px solid rgba(140, 135, 169, 0.2);
            margin: 0;
            padding: 0 20px 40px;

            .el-col {
                padding: 0 10px;
                box-sizing: border-box;
            }
        }

        @media screen and (max-width: 768px) {
            /deep/ .el-row {
                padding: 0 15px 25px;

                .el-col {
                    width: 100% !important;
                    padding: 0 12px;
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .footer-item {
                margin-bottom: 0;

                .article-box {
                    .title {
                        font-size: 15px;
                        margin-bottom: 12px;
                        font-weight: 500;
                        padding: 0 4px;
                    }

                    .article-item {
                        font-size: 14px;
                        margin-bottom: 4px;
                        line-height: 1.5;
                        padding: 8px 4px;
                        position: relative;

                        &:active {
                            background: rgba(255, 255, 255, 0.05);
                            border-radius: 4px;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 576px) {
            /deep/ .el-row {
                padding: 0 12px 20px;

                .el-col {
                    padding: 0 8px;
                    margin-bottom: 16px;
                }
            }

            .footer-item {
                .article-box {
                    .title {
                        font-size: 14px;
                        margin-bottom: 10px;
                    }

                    .article-item {
                        font-size: 13px;
                        padding: 6px 4px;
                    }
                }

                &.cus-flex-y {
                    .el-image {
                        width: 100px !important;
                        height: 69px !important;
                    }
                }
            }
        }
    }

    .article-box {
        text-align: left;

        .title {
            color: rgba(255, 255, 255, 0.9);
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 500;
        }

        .article-item {
            color: rgba(255, 255, 255, 0.7);
            margin-bottom: 14px;
            cursor: pointer;
            transition: all 0.3s ease;
            line-height: 1.6;
            display: block;
            padding: 4px 0;

            &:hover {
                color: #fff;
            }

            &:active {
                color: #fff;
                background: rgba(255, 255, 255, 0.1);
            }

            span {
                display: inline-block;
            }
        }

        @media screen and (max-width: 768px) {
            .article-item {
                padding: 6px 0;
                margin-bottom: 8px;
            }
        }
    }

    .cus-text {
        font-weight: 400;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.8);
        line-height: 1.6;
    }

    .box {
        color: rgba(255, 255, 255, 0.6);
        padding: 20px;
        font-size: 13px;
        text-align: center;
        border-top: 1px solid rgba(255, 255, 255, 0.1);

        .el-link {
            color: rgba(255, 255, 255, 0.6);
            font-size: 13px;
            transition: color 0.3s ease;

            &:hover {
                color: #fff;
            }
        }
    }
}
</style>
