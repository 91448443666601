<template>
    <section class="header" :class="{ 'fade-in-down': show }">
        <el-image class="logo" :src="$config.get('logo')" fit="contain" lazy @click="handleLogoClick"></el-image>
        <ul class="menu-list" v-if="!isMyPage">
            <li class="menu-item" @click="handleClick('Home')">
                <span :class="{ 'active': current === 'Home' }">{{ $t('nav.home') }}</span>
            </li>
            <li class="menu-item" @click="handleClick('Price')">
                <span :class="{ 'active': current === 'Price' }">{{ $t('nav.price') }}</span>
            </li>
            <li class="menu-item" @click="handleClick('Product')">
                <span :class="{ 'active': current === 'Product' }">{{ $t('nav.product') }}</span>
            </li>
            <li class="menu-item">
                <el-dropdown>
                    <span :class="{ 'active': current === 'Resource' }" class="cus-h6">
                        {{ $t('nav.resourceCenter') }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                            <div class="cus-body" @click="jumpExternalPage('1863883454077337600')">
                                <span>{{ $t('nav.introductionGuide') }}</span>
                            </div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <div class="cus-body" @click="jumpExternalPage('1863883565616463872')">{{
                                $t('nav.commonProblem') }}</div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <div class="cus-body" @click="jumpExternalPage('1863883715722215424')">{{
                                $t('nav.adsPowerAgentConfigurationTutorial') }}</div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <div class="cus-body" @click="jumpExternalPage('1863883810739978240')">{{
                                $t('nav.adsPowerDynamicProxyConfigurationTutorial') }}</div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <div class="cus-body" @click="jumpExternalPage('1863883876338892800')">{{
                                $t('nav.bitBrowserStaticProxyConfigurationTutorial') }}</div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <div class="cus-body" @click="jumpExternalPage('1867772753352982528')">{{ $t('nav.blog') }}
                            </div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </li>
        </ul>
        <div class="cus-flex-x cus-flex-y-center">
            <el-dropdown @command="handleLanguageSwitch">
                <span class="cus-h6 cus-pointer">
                    <svg-icon icon-class="qiehuanyuyan" style="width: 16px;height: 16px;" />
                    <span style="margin-left: 6px;">{{ $t('header.language.' + $store.getters.lang) }}</span>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :disabled="$store.getters.lang === 'zh-TW'" command="zh-TW"><span
                            class="cus-h6">{{
                                $t('header.language.zh-TW')
                            }}</span></el-dropdown-item>
                    <el-dropdown-item :disabled="$store.getters.lang === 'zh-CN'" command="zh-CN"><span
                            class="cus-h6">{{
                                $t('header.language.zh-CN')
                            }}</span></el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-link :underline="false" v-if="$store.getters.hasLogin" @click="handleClick('My')" class="cus-ml-5">{{
                $store.getters.name }}</el-link>
            <div class="btn-box" v-if="!$store.getters.hasLogin && !isMyPage && !isAuthPage">
                <el-button type="default" @click="$router.push('/login')">{{
                    $t('nav.login') }}</el-button>
                <el-button type="primary" @click="$router.push('/registration')">{{ $t('nav.registration') }}</el-button>
            </div>
            <el-image v-if="!isMyPage" class="more" @click="drawer = !drawer"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwBAMAAAClLOS0AAAAAXNSR0IArs4c6QAAAA9QTFRFAAAANTU1MjIyMzMzMzMzB9e/YQAAAAR0Uk5TAD9/v2/dDxMAAAA2SURBVDjLY2AYBdQBLC4owIECCWZjFGBAV38wCqIAAVp4kEkJBSjQ1YM4LaeiB3EG4iigDAAAoIss1M+Rr6UAAAAASUVORK5CYII="
                fit="contain"></el-image>
        </div>
        <el-drawer v-if="!isMyPage" style="top: 60px" :with-header="true" size="100%" :modal="false" :visible.sync="drawer"
            direction="ltr" class="drawer-box">
            <ul class="drawer-menu-list">
                <li class="drawer-menu-item cus-maintitle" @click="handleClick('Home')">
                    <div class="item-title">{{ $t('nav.home') }}</div>
                </li>
                <li class="drawer-menu-item cus-maintitle" @click="handleClick('Price')">
                    <div class="item-title">{{ $t('nav.price') }}</div>
                </li>
                <li class="drawer-menu-item cus-maintitle" @click="handleClick('Product')">
                    <div class="item-title">{{ $t('nav.product') }}</div>
                </li>
                <li class="drawer-menu-item cus-maintitle">
                    <div class="cus-maintitle cus-tile-x cus-flex-x cus-flex-y-center cus-flex-between" @click="resourceDropdownVisible = !resourceDropdownVisible">
                        <div>{{ $t('nav.resourceCenter') }}</div>
                        <div>{{ resourceDropdownVisible ? '-' : '+' }}</div>
                    </div>
                    <div v-show="resourceDropdownVisible" class="resource-dropdown">
                        <div class="dropdown-item" @click="jumpExternalPage('1863883454077337600')">
                            <span>{{ $t('nav.introductionGuide') }}</span>
                        </div>
                        <div class="dropdown-item" @click="jumpExternalPage('1863883565616463872')">
                            {{ $t('nav.commonProblem') }}
                        </div>
                        <div class="dropdown-item" @click="jumpExternalPage('1863883715722215424')">
                            {{ $t('nav.adsPowerAgentConfigurationTutorial') }}
                        </div>
                        <div class="dropdown-item" @click="jumpExternalPage('1863883810739978240')">
                            {{ $t('nav.adsPowerDynamicProxyConfigurationTutorial') }}
                        </div>
                        <div class="dropdown-item" @click="jumpExternalPage('1863883876338892800')">
                            {{ $t('nav.bitBrowserStaticProxyConfigurationTutorial') }}
                        </div>
                    </div>
                </li>
            </ul>
            <div class="cus-flex-x box-wap">
                <el-button class="cus-flex-fill" type="default" @click="$router.push('/login')"
                    v-if="!$store.getters.hasLogin && !isAuthPage">{{ $t('nav.login') }}</el-button>
                <el-button class="cus-flex-fill" type="primary" @click="$router.push('/registration')"
                    v-if="!$store.getters.hasLogin && !isAuthPage">{{ $t('nav.registration') }}</el-button>
            </div>
        </el-drawer>
    </section>
</template>

<script>
export default {
    name: 'Header',
    data() {
        return {
            show: false,
            drawer: false,
            current: 'Home',
            resourceDropdownVisible: false
        }
    },
    computed: {
        isMyPage() {
            return this.$route.path.startsWith('/my') && this.$store.getters.hasLogin
        },
        isAuthPage() {
            return this.$route.path.startsWith('/login') || this.$route.path.startsWith('/registration') || this.$route.path.startsWith('/forget-passwd')
        }
    },
    watch: {
        $route: {
            handler: function (route) {
                this.current = route.name
            },
            immediate: true
        }
    },
    mounted() {
        this.current = this.$route.name
        window.addEventListener("scroll", this.handleScroll)
    },
    methods: {
        handleScroll() {
            console.log('scroll...')
            this.show = window.scrollY > 0
        },
        handleLogoClick() {
            if (this.$store.getters.hasLogin) {
                this.$router.push({ name: 'My' })
            }
        },
        handleLanguageSwitch(lang) {
            this.$i18n.locale = lang;
            this.$store.commit("app/" + this.$mutationTypes.SET_LANGUAGE, lang);
        },
        jumpExternalPage(id) {
            this.current = 'Resource'
            this.resourceDropdownVisible = false;
            this.$router.replace({
                name: 'Article',
                query: {
                    id
                }
            }).then(() => {
                this.drawer = false;
            })
        },
        handleClick(e) {
            this.drawer = false;

            if (this.$route.name === e) {
                return;
            }

            if (e === 'My' && !this.$store.getters.hasLogin) {
                this.$router.push('/login');
                return;
            }

            try {
                this.$router.push({ name: e }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        console.error('Navigation failed:', err);
                    }
                });
            } catch (error) {
                const routeMap = {
                    'Home': '/',
                    'Price': '/price',
                    'Product': '/product',
                    'My': '/my'
                };

                const path = routeMap[e];
                if (path) {
                    this.$router.push(path).catch(err => {
                        if (err.name !== 'NavigationDuplicated') {
                            console.error('Navigation failed:', err);
                        }
                    });
                }
            }
        }
    },
}
</script>

<style lang="scss" scoped>
$--color-primary: #9E3AFF;

.header {
    position: fixed;
    z-index: 9999;
    box-sizing: border-box;
    padding: 14px 40px;
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    transition: all 0.3s ease;
    pointer-events: all;

    * {
        pointer-events: all;
    }

    &.fade-in-down {
        background-color: rgba(255, 255, 255, 0.98);
        backdrop-filter: blur(10px);
        animation-duration: .5s;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
        animation-name: fadeInDown;
    }

    @keyframes fadeInDown {
        0% {
            opacity: 0;
            transform: translate3d(0, -40px, 0);
        }

        100% {
            opacity: 1;
            transform: translateZ(0);
        }
    }

    .menu-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        position: relative;
        z-index: 10000;

        .menu-item {
            padding: 0 25px;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.3s ease;
            position: relative;
            z-index: 10000;

            &:hover {
                color: $--color-primary;
                transform: translateY(-1px);
            }

            .active {
                color: $--color-primary;
                position: relative;
                
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -14px;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    background-color: $--color-primary;
                    border-radius: 2px;
                    transition: all 0.3s ease;
                }
            }
        }
    }

    .btn-box {
        margin-left: 20px;
        display: flex;
        gap: 12px;

        .el-button {
            transition: all 0.3s ease;
            border-radius: 8px;
            padding: 10px 20px;
            
            &:hover {
                transform: translateY(-1px);
            }
        }
    }

    .logo {
        cursor: pointer;
        width: 104px;
        height: 72px;
        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1.02);
        }
    }

    .more {
        display: none;
        cursor: pointer;
        width: 40px;
        height: 40px;
        padding: 8px;
        border-radius: 8px;
        transition: all 0.3s ease;

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }

    @media screen and (min-width: 1025px) {
        .drawer-box {
            display: none !important;
        }
    }

    @media screen and (max-width: 1024px) {
        .menu-list {
            display: none;
        }

        .box-wap {
            margin-top: 20px;
            padding: 20px;
            position: fixed;
            width: 100%;
            bottom: 10px;
            display: flex;
            gap: 12px;

            .el-button {
                flex: 1;
                border-radius: 8px;
            }
        }

        .drawer-menu-list {
            .drawer-menu-item {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                margin: 0 20px;
                padding: 12px 16px;
                cursor: pointer;
                border-radius: 8px;
                transition: all 0.3s ease;

                &:hover {
                    color: $--color-primary;
                    background-color: rgba(158, 58, 255, 0.05);
                }

                .resource-dropdown {
                    margin-top: 10px;
                    
                    .dropdown-item {
                        padding: 8px 16px;
                        margin: 4px 0;
                        border-radius: 4px;
                        transition: all 0.3s ease;
                        font-size: 14px;
                        
                        &:hover {
                            background-color: rgba(158, 58, 255, 0.1);
                        }
                    }
                }
            }
        }

        .btn-box {
            display: none;
        }

        .logo {
            width: 80px;
            height: 42px;
        }

        .more {
            display: inline-block;
        }
    }
}

@media screen and (max-width: 1024px) {
    .header {
        padding: 14px 16px !important;
        height: 70px !important;
    }
}
</style>
