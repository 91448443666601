export default {
  route: {
    Login: 'Login',
    Registration: 'Registration',
    ForgetPasswd: 'Forget Passwd',
    Home: 'Home',
    Price: 'Price',
    Product: 'Product',
    My: 'A globally secure and reliable IP proxy service provider',
    Error: 'A globally secure and reliable IP proxy service provider'
  },
  nav: {
    home: 'Home',
    price: 'Price',
    product: 'Product',
    resourceCenter: 'Resource Center',
    login: 'Login',
    registration: 'Registration',
    introductionGuide: 'Introduction Guide',
    commonProblem: 'Common Problem',
    adsPowerAgentConfigurationTutorial: 'AdsPower Agent Configuration Tutorial',
    adsPowerDynamicProxyConfigurationTutorial: 'AdsPower Dynamic Proxy Configuration Tutorial',
    bitBrowserStaticProxyConfigurationTutorial: 'Bit Browser Static Proxy Configuration Tutorial',
    blog: 'Blog'
  },
  header: {
    language: {
      'zh-CN': 'zh-CN',
      'zh-TW': 'zh-TW',
      en: 'en'
    },
    switchLanguage: 'switchLanguage'
  },
  footer: {
    contactus: 'Contact Us',
    email: 'Email',
    phone: 'Phone',
    product: 'Product',
    resourceService: 'Resources and Services',
    useCase: 'Use Case',
    legalDisclaimer: 'Legal Disclaimer',
    dynamicProxy: 'Dynamic Real Person Residential Agency',
    ipv4: 'Data Center IPv4 Proxy',
    ipv6: 'Data Center IPv6 Proxy',
    staticISP: 'Static residential agency',
    price: 'Price',
    QA: 'Q&A',
    useCase: 'Use Case',
    serviceClause: 'Service And Clause',
    lawAndPrivacy: 'Law And Privacy'
  },
  global: {
    title: 'A globally secure and reliable IP proxy service provider',
    confirmLogout: 'Confirm Logout',
    loginAgain: 'Log in again',
    cancel: 'Cancel',
    error: 'Error',
    learnMore: 'Learn More',
    buyNow: 'Buy Now',
    confirm: 'Confirm',
    cancelDone: 'Cancel successfully',
    edit: 'Edit'
  },
  user: {
    nickNameBlank: 'The nickName can not be empty！',
    passwordBlank: 'The password can not be empty！',
  },
  auth: {
    loginBtn: 'Login',
    loginLink: 'Login',
    registrationBtn: 'Registration',
    registrationLink: 'Register Now',
    loginTitle: 'Account Login',
    registrationTitle: 'Account Registration',
    emailPlaceHolder: 'Please input the email',
    passwordPlaceHolder: 'Please input the password',
    repasswordPlaceHolder: 'Please input the rePassword',
    rememberPassword: 'Remember Password',
    forgetPassword: 'Forget Password',
    hasAccountTips: 'Do you already have an account?',
    readAgreement: 'I have read and agree',
    userAgreement: 'User Service Agreement',
    captchaPlaceHolder: 'Please input the captcha',
    sendCaptchaLink: 'Get the captcha',
    sendCaptchaAgain: 'Resend in {second} seconds',
    forgetPasswdTitle: 'Reset Password',
    newpasswordPlaceHolder: 'Please input the new password',
    confirm: 'Confirm',
    sendCaptchSuccess: 'The verification code has been sent to your registered email',
    readAgreementConfirm: 'Please read and agree to the User Service Agreement first'
  },
  home: {
    goodsTitle: 'Our products',
    advantageTitle: 'Our advantages',
    partnerTitle: 'Cooperative partner',
  },
  product: {
    title: 'Our products',
    dynamic: {
      iconClass: '',
      title: 'Dynamic Real Person Residential Agency',
      description: 'The most authentic dynamic residential agent enhances the stability and credibility of the agent by frequently changing residential IP addresses, with high security and covert bypass of network restrictions and blockades',
      advantageList: [
        {
          name: 'latent ',
          description: 'Top level security concealment in the industry'
        },
        {
          name: '<div style="display:flex;flex-direction:row;align-items:flex-start;"><span style="font-size:14px">3000万</span><span style="font-size: 7px">+</span></div>',
          description: 'Compliant real residential IP proxy network'
        },
        {
          name: '99.97%',
          description: 'Normal operating hours'
        },
        {
          name: '179 pieces',
          description: 'Country Location'
        },
        {
          name: 'support',
          description: 'HTTP(S) & SOCKS5 Agreement'
        }
      ],
      introduction: {
        title: 'What is Dynamic Residential IP',
        description: 'Dynamic residential proxy is a proxy service provided through countless real residential IP addresses. Dynamic residential proxy will regularly change IP addresses and mimic the online behavior of ordinary household users, making it difficult for target websites to detect as a proxy.',
        tips: 'The price is as low as',
        buyNow: 'Buy Now',
        mainTitle: 'main features',
        mainData: [
          {
            name: 'Dynamic nature',
            description: 'The IP address will constantly rotate, increasing concealment and success rate'
          },
          {
            name: 'Real residential IP',
            description: 'The IP provided by the actual ISP appears to be a request from an ordinary user'
          },
          {
            name: 'High anonymity',
            description: 'Difficult to detect and block, suitable for bypassing geographical restrictions and accessing sensitive information'
          }
        ]
      }
    },
    staticISP: {
      iconClass: '',
      title: 'Exclusive static ISP proxy',
      description: 'Assign active IP addresses from global Internet service providers (ISPs) to enable users to use the same residential IP for a long time as needed. This exclusive static residential agent is not only fast and highly reliable, but also effectively simulates the operational behavior of real users',
      advantageList: [
        {
          name: 'Real user simulation',
          description: 'More difficult for websites to identify as proxies, improving concealment'
        },
        {
          name: 'High anonymity',
          description: 'Reduced the risk of being monitored and blocked'
        },
        {
          name: 'Low blockade rate',
          description: 'The probability of being blocked by websites when using IP is relatively low'
        },
        {
          name: 'flexibility',
          description: 'Users can use the same IP address for a long time according to their needs'
        },
        {
          name: 'Better compatibility',
          description: 'Support more types of websites and services'
        }
      ],
      introduction: {
        title: 'Real user simulation',
        description: 'Exclusive ISP residential agents use the IP address of actual household users, which is more difficult for websites to recognize as agents and thus improves concealment.',
        tips: 'The price is as low as',
        buyNow: 'Buy Now',
        mainData: [
          {
            name: 'High anonymity'
          },
          {
            name: 'Low blockade rate',
          },
          {
            name: 'flexibility',
          },
          {
            name: 'Better compatibility',
          }
        ]
      }
    },
    static: {
      iconClass: '',
      title: 'Static proxy',
      description: 'Static IPv4 is a fixed IP address allocated by service providers (ISPs), exclusively used by ISP residential agents to ensure speed while reducing the risk of being monitored and blocked, allowing users to continue using the same residential IP',
      advantageList: [
        {
          name: 'stability',
          description: 'Always use the same IP address to ensure connection stability'
        },
        {
          name: 'High anonymity',
          description: 'Less detected and blocked, suitable for operations that require privacy protection'
        },
        {
          name: 'Fast speed',
          description: 'Suitable for tasks such as data capture and other high bandwidth requirements'
        },
        {
          name: 'reliability',
          description: 'Avoiding speed reduction or connection issues caused by shared proxies'
        },
        {
          name: 'Controllability',
          description: 'Users can better manage and monitor their IP usage'
        }
      ],
      introduction: {
        title: 'Static proxy',
        description: 'Static IPv4 is a fixed IP address allocated by service providers (ISPs), exclusively used by ISP residential agents to ensure speed while reducing the risk of being monitored and blocked, allowing users to continue using the same residential IP',
        tips: 'The price is as low as',
        buyNow: 'Buy Now',
        mainData: [
          {
            name: 'stability',
            description: 'Always use the same IP address to ensure connection stability'
          },
          {
            name: 'High anonymity',
            description: 'Less detected and blocked, suitable for operations that require privacy protection'
          },
          {
            name: 'Fast speed',
            description: 'Suitable for tasks such as data capture and other high bandwidth requirements'
          },
          {
            name: 'reliability',
            description: 'Avoiding speed reduction or connection issues caused by shared proxies'
          },
          {
            name: 'Controllability',
            description: 'Users can better manage and monitor their IP usage'
          }
        ]
      }
    }
  },
  advantage: {
    stability: 'Stability',
    fastspeed: 'Fast Speed',
    highanonymity: 'High Anonymity',
    reliability: 'Reliability',
    controllability: 'Controllability'
  },
  price: {
    title: 'Diversified Prices',
    dynamic: 'Dynamic Real Person Residential Agency',
    staticISP: 'Exclusive static ISP proxy',
    static: 'Static proxy',
    outOfStock: 'Out Of Stock',
    dayPackage: 'day package',
    advantage: [
      {
        name: 'Security and Privacy Protection'
      },
      {
        name: '300+countries with millions of agent pools'
      },
      {
        name: 'Excellent network speed'
      },
      {
        name: 'Highly scalable'
      },
      {
        name: '99.9% normal running time'
      },
      {
        name: 'Intelligent traffic management'
      }
    ]
  },
  my: {
    buyProxy: 'Buy Proxy',
    dashboard: 'Dashboard',
    proxyManagement: 'Proxy Management',
    dynamic: 'Dynamic residential agency',
    proxySetting: 'Proxy Setting',
    authUser: 'Auth User List',
    myAccount: 'My Account',
    accountSetting: 'Account Setting',
    logout: 'Logout',
    leftFlow: 'Remaining Traffic',
    expirationDate: 'Expiration Date',
    country: 'Country',
    stateCityOrISP: 'State-City/ISP',
    stateCity: 'State-City',
    ISP: 'ISP',
    state: 'State',
    city: 'City',
    authUser: 'Auth User',
    createAuthUser: 'Create Auth User',
    recharge: 'Recharge',
    selectPlaceHolder: 'Please Select',
    changeMode: 'Change Mode',
    randomIP: 'Random IP',
    delayIP: 'Delay IP',
    delayDuration: 'IP Delay Duration（1-120min）',
    hostAndPort: 'Host IP:Port',
    proxyUsername: 'Proxy Username',
    proxyPassword: 'Proxy password',
    tips: 'Due to policy reasons, our proxy service is not supported in Chinese Mainland.',
    proxyAddress1: 'Username:Password:Host IP:Port',
    proxyAddress2: 'Host IP:Port:Username:Password',
    proxyAddress3: 'Username:password@Host IP:Port',
    addBatch: 'Add Batch',
    copyList: 'Copy the proxy list',
    userPlaceHolder: 'Input the username',
    search: 'Search',
    addAuthUser: 'Add Auth User',
    username: 'Username',
    flowLimit: 'Traffic usage limit/G',
    status: 'Status',
    totalConsume: 'Total Consume',
    createTime: 'Create Time',
    password: 'Password',
    usernamePlaceHolder: 'Supports 6-24 characters, letters/numbers/underscores',
    usernameEmpty: 'Please input a username',
    usernameError: 'Please enter a username of 6-24 characters',
    passwordPlaceHolder: 'Password requires 6-15 characters and does not support special symbols',
    passwordEmpty: 'Please input a password',
    passwordError: 'Password requires 6-15 characters and does not support special symbols',
    flowLimitGB: 'Traffic limit（GB）',
    orderDetail: 'Order Detail',
    startAt: 'Start Date',
    endAt: 'End Date',
    orderNoPlaceHolder: 'Input the orderNo',
    orderNo: 'OrderNo',
    packageDuration: 'Package Duration',
    flow: 'Traffic',
    totalAmount: 'Total Amount',
    orderStatus: 'Order Status',
    paymentTime: 'Payment Time',
    actions: 'Actions',
    toPay: 'To Pay',
    proxyType: 'Proxy Type',
    selectPackage: 'Select Package',
    purchaseNotice: '<p><strong> Purchase Notice</strong></p><p>1. The agent sold for this service is a fixed-term package that cannot be retrieved after expiration. Please pay attention to the renewal time.</p><p>2. After being sold by an agent, <span style="color: rgb(225, 60, 57);">returns and exchanges are not supported</span>. Please confirm the type and region of the agent before purchasing to avoid making a mistake.</p>',
    day: 'Day',
    payableAmount: 'Amount',
    flow: 'Flow/GB',
    payType: 'Pay Type',
    alipay: 'Alipay',
    alipayPay: 'Alipay Pay',
    payAgreement: 'Payment is considered as agreement',
    agreement: 'Service Agreement',
    agreementError: 'Please read and agree to the payment service agreement!',
    pendingPay: 'Not Paid',
    finishPay: 'Paid',
    cancelPay: 'Close',
    avatar: 'Avatar',
    email: 'Email',
    nick: 'Nick'
  },
  test: '测试'
}