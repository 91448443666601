<template>
<el-container>
    <el-header height="80px">
        <Header></Header>
    </el-header>
    <app-main></app-main>

    <el-footer v-if="!hideArr.includes(current)">
        <Footer></Footer>
    </el-footer>
</el-container>
</template>

<script>
import {
    Header,
    AppMain,
    Footer
} from '@/layout/components/index'
export default {
    name: 'Layout',
    components: {
        Header,
        AppMain,
        Footer
    },
    data() {
        return {
            hideArr: ['Login', 'Registration', 'ForgetPasswd'],
            current: ''
        }
    },
    watch: {
        $route: {
            handler: function (route) {
                this.current = route.name
            },
            immediate: true
        }
    },
    mounted() {
        this.current = this.$route.name
    },
}
</script>

<style lang="scss" scoped>
.el-header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
}

/deep/ .el-footer {
    height: 80px !important;
}

@media screen and (max-width: 1024px) {
    /deep/ .el-header {
        height: 70px !important;
    }
}
</style>
