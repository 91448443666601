import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
const _import = require('@/router/_import_' + process.env.NODE_ENV)
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Home',
        component: _import('Home'),
        meta: {
          title: 'Home',
          auth: false
        }
      }
    ]
  },
  {
    path: '/login',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Login',
        component: _import('Login'),
        meta: {
          title: 'Login',
          auth: false
        }
      }
    ]
  },
  {
    path: '/registration',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Registration',
        component: _import('Registration'),
        meta: {
          title: 'Registration',
          auth: false
        }
      }
    ]
  },
  {
    path: '/forget-passwd',
    component: Layout,
    children: [
      {
        path: '',
        name: 'ForgetPasswd',
        component: _import('ForgetPasswd'),
        meta: {
          title: 'ForgetPasswd',
          auth: false
        }
      }
    ]
  },
  {
    path: '/price',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Price',
        component: _import('Price'),
        meta: {
          title: 'Price',
          auth: false
        }
      }
    ]
  },
  {
    path: '/product',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Product',
        component: _import('Product'),
        meta: {
          title: 'Product',
          auth: false
        }
      }
    ]
  },
  {
    path: '/article',
    component: Layout,
    children: [
      {
        path: '',
        component: _import('Article'),
        name: 'Article',
        meta: {
          title: 'Article',
          auth: false
        }
      }
    ]
  },
  {
    path: '/my',
    component: Layout,
    children: [
      {
        path: '',
        component: _import('My'),
        name: 'My',
        meta: {
          auth: true,
          title: 'My'
        },
        redirect: '/my/buy',
        children: [
          {
            path: 'buy',
            name: 'MyBuy',
            component: _import('My'),
            meta: {
              auth: true,
              title: 'MyBuy'
            }
          },
          {
            path: 'dashboard',
            name: 'MyDashboard',
            component: _import('My'),
            meta: {
              auth: true,
              title: 'MyDashboard'
            }
          },
          {
            path: 'accountSetting',
            name: 'MyAccountSetting',
            component: _import('My'),
            meta: {
              auth: true,
              title: 'MyAccountSetting'
            }
          },
          {
            path: 'dynamicOrder',
            name: 'MyDynamicOrder',
            component: _import('My'),
            meta: {
              auth: true,
              title: 'MyDynamicOrder'
            }
          },
          {
            path: 'proxyAccount',
            name: 'MyProxyAccount',
            component: _import('My'),
            meta: {
              auth: true,
              title: 'MyProxyAccount'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/pay',
    redirect: '/pay/cash-register',
    component: Layout,
    children: [
      {
        path: 'cash-register',
        component: _import('CashRegister'),
        name: 'CashRegister',
        meta: {
          auth: true,
          title: 'CashRegister'
        }
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404.vue'),
    name: 'ErrorNotFound',
    meta: {
      auth: false,
      title: 'NotFound'
    }
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401.vue'),
    name: 'Unauthorized',
    meta: {
      auth: false,
      title: 'Unauthorized'
    }
  },
  { path: '*', redirect: '/404' }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
