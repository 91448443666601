export default {
  route: {
    Login: '登录',
    Registration: '注册',
    ForgetPasswd: '忘记密码',
    Home: '首页',
    Price: '价格',
    Product: '产品',
    My: '全球安全可靠的IP代理服务商',
    Error: '全球安全可靠的IP代理服务商',
    CashRegister: '收银台',
    Article: '文章详情',
    NotFound: '页面未找到',
    Unauthorized: '未授权访问',
    MyBuy: '购买服务',
    MyDynamicOrder: '动态订单',
    MyProxyAccount: '代理账户',
    MyAccountSetting: '账户设置',
    MyDashboard: '控制台'
  },
  nav: {
    home: '首页',
    price: '价格',
    product: '产品',
    resourceCenter: '资源中心',
    login: '登录',
    registration: '注册',
    introductionGuide: '入门指导',
    commonProblem: '常见问题',
    adsPowerAgentConfigurationTutorial: 'AdsPower代理配置教程',
    adsPowerDynamicProxyConfigurationTutorial: 'AdsPower动态代理配置教程',
    bitBrowserStaticProxyConfigurationTutorial: '比特浏览器静态代理配置教程',
    blog: '博客'
  },
  header: {
    language: {
      'zh-CN': '简体中文',
      'zh-TW': '繁体中文',
      en: '英文'
    },
    switchLanguage: '切换语言'
  },
  footer: {
    contactus: '联系我们',
    email: '邮箱',
    phone: '电话',
    product: '产品',
    resourceService: '资源与服务',
    useCase: '使用案例',
    legalDisclaimer: '法律声明',
    dynamicProxy: '动态真人住宅代理',
    ipv4: '数据中心IPv4代理',
    ipv6: '数据中心IPv6代理',
    staticISP: '静态住宅代理',
    price: '价格',
    QA: 'Q&A',
    useCase: '使用案例',
    serviceClause: '服务与条款',
    lawAndPrivacy: '法律与隐私'
  },
  global: {
    title: '全球安全可靠的IP代理服务商',
    confirmLogout: '确认注销',
    loginAgain: '重新登录',
    cancel: '取消',
    error: '错误',
    learnMore: '了解更多',
    buyNow: '立即购买',
    confirm: '确定',
    cancelDone: '取消成功',
    edit: '修改',
    goBack: '返回'
  },
  user: {
    nickNameBlank: '昵称不能为空！',
    passwordBlank: '密码不能为空！',
  },
  auth: {
    loginBtn: '登录',
    loginLink: '登录',
    registrationBtn: '注册',
    registrationLink: '立即注册',
    loginTitle: '账号登录',
    registrationTitle: '账号注册',
    emailPlaceHolder: '请输入邮箱',
    passwordPlaceHolder: '请输入密码',
    repasswordPlaceHolder: '请输入确认密码',
    rememberPassword: '记住密码',
    forgetPassword: '忘记密码',
    hasAccountTips: '已有账号？',
    readAgreement: '我已阅读并同意',
    userAgreement: '用户服务协议',
    captchaPlaceHolder: '请输入验证码',
    sendCaptchaLink: '获取验证码',
    sendCaptchaAgain: '{second}秒后可重发',
    forgetPasswdTitle: '重置密码',
    newpasswordPlaceHolder: '请输入新密码',
    confirm: '确认',
    sendCaptchSuccess: '验证码已发送至你的注册邮箱',
    readAgreementConfirm: '请先阅读并同意用户服务协议'
  },
  home: {
    goodsTitle: '我们的产品',
    advantageTitle: '我们的优势',
    partnerTitle: '合作伙伴',
  },
  product: {
    title: '我们的产品',
    dynamic: {
      iconClass: '',
      title: '动态真人住宅代理',
      description: '最真实的动态住宅代理，通过高频次更换住宅IP地址来增强代理的稳定性与可信度，高安全性和隐蔽地绕过网络限制和封锁',
      advantageList: [
        {
          name: '隐匿性',
          description: '业内顶尖级别的安全隐匿性'
        },
        {
          name: '<div style="display:flex;flex-direction:row;align-items:flex-start;"><span style="font-size:14px">3000万</span><span style="font-size: 7px">+</span></div>',
          description: '合规的真人住宅IP代理网络'
        },
        {
          name: '99.97%',
          description: '正常运营时间'
        },
        {
          name: '179个',
          description: '国家位置'
        },
        {
          name: '支持',
          description: 'HTTP(S) & SOCKS5协议'
        }
      ],
      introduction: {
        title: '什么是动态住宅IP.',
        description: '动态住宅代理是通过无数真实住宅IP地址提供的代理服务，动态住宅代理会定期更换IP地址，模仿普通家庭用户的上网行为，因而难以被目标网站检测到为代理。',
        tips: '价格低至',
        buyNow: '立即选购',
        mainTitle: '主要特点',
        mainData: [
          {
            name: '动态性',
            description: 'IP地址会不断轮换，增加隐蔽性和成功率'
          },
          {
            name: '真实住宅IP',
            description: '来自实际的ISP提供的IP，因而看起来像普通用户的请求'
          },
          {
            name: '高匿名性',
            description: '难以被检测和封锁，适合绕过地理限制和访问敏感信息'
          }
        ]
      }
    },
    staticISP: {
      iconClass: '',
      title: '独享静态ISP代理',
      description: '从全球互联网服务提供商(ISP)分配活跃IP地址，使用户能够根据需要长期使用相同的住宅IP。这种独享静态住宅代理不仅速度快且高度可靠，有效模拟真实用户的操作行为',
      advantageList: [
        {
          name: '真实用户模拟',
          description: '更难被网站识别为代理，提高隐蔽性'
        },
        {
          name: '高匿名性',
          description: '减少了被监测和封锁的风险'
        },
        {
          name: '低封锁率',
          description: '使用IP时被网站封锁的概率较低'
        },
        {
          name: '灵活性',
          description: '用户可以根据需求长期使用相同的IP'
        },
        {
          name: '更好的兼容性',
          description: '支持更多类型的网站和服务'
        }
      ],
      introduction: {
        title: '真实用户模拟',
        description: '独享ISP住宅代理使用的是实际家庭用户的IP地址，更难被网站识别为代理，从而提高隐蔽性。',
        tips: '价格低至',
        buyNow: '立即选购',
        mainData: [
          {
            name: '高匿名性'
          },
          {
            name: '低封锁率',
          },
          {
            name: '灵活性',
          },
          {
            name: '更好的兼容性',
          }
        ]
      }
    },
    static: {
      iconClass: '',
      title: '静态代理',
      description: '静态IPv4是由服务提供商(ISP)分配的固定IP地址，独享ISP住宅代理利用这些静态IP，确保速度的同时降低被监测和屏蔽的风险让用户可以持续使用相同的住宅IP',
      advantageList: [
        {
          name: '稳定性',
          description: '始终使用相同的IP地址，确保连接的稳定性'
        },
        {
          name: '高匿名性',
          description: '较少被检测和封锁，适合需要隐私保护的操作'
        },
        {
          name: '快速速度',
          description: '适合数据抓取和其他高带宽需求的任务'
        },
        {
          name: '可靠性',
          description: '避免了共享代理带来的速度下降或连接问题'
        },
        {
          name: '可控性',
          description: '用户可以更好地管理和监控自己的IP使用情况'
        }
      ],
      introduction: {
        title: '静态代理',
        description: '静态IPv4是由服务提供商(ISP)分配的固定IP地址，独享ISP住宅代理利用这些静态IP，确保速度的同时降低被监测和屏蔽的风险让用户可以持续使用相同的住宅IP',
        tips: '价格低至',
        buyNow: '立即选购',
        mainData: [
          {
            name: '稳定性',
            description: '始终使用相同的IP地址确保连接的稳定性'
          },
          {
            name: '高匿名性',
            description: '较少被检测和封锁，适合需要隐私保护的操作'
          },
          {
            name: '快速速度',
            description: '适合数据抓取和其他高带宽需求的任务'
          },
          {
            name: '可靠性',
            description: '避免了共享代理带来的速度下降或连接问题'
          },
          {
            name: '可控性',
            description: '用户可以更好地管理和监控自己的IP使用情况'
          }
        ]
      }
    }
  },
  advantage: {
    stability: '稳定性',
    fastspeed: '快速速度',
    highanonymity: '高匿名性',
    reliability: '可靠性',
    controllability: '可控性'
  },
  price: {
    title: '多样化的价格',
    outOfStock: '缺货',
    comingSoon: '即将上线',
    dayPackage: '天套餐',
    dynamic: '动态真人住宅代理',
    staticISP: '独享静态ISP代理',
    static: '静态代理',
    advantage: [
      {
        name: '安全与隐私保障'
      },
      {
        name: '300+国家 上千万代理池'
      },
      {
        name: '卓越的网络速度'
      },
      {
        name: '高度可扩展性'
      },
      {
        name: '99.9%正常运行时间'
      },
      {
        name: '智能流量管理'
      }
    ]
  },
  my: {
    buyProxy: '购买代理',
    dashboard: '仪表盘',
    proxyManagement: '代理管理',
    dynamic: '动态住宅代理',
    proxySetting: '代理设置',
    authUser: '授权用户列表',
    myAccount: '我的账户',
    accountSetting: '账号设置',
    logout: '退出',
    leftFlow: '剩余流量',
    expirationDate: '到期时间',
    country: '国家',
    stateCityOrISP: '州-城市/ISP',
    stateCity: '州-城市',
    ISP: 'ISP',
    state: '州',
    city: '城市',
    authUser: '授权用户',
    createAuthUser: '创建授权用户',
    recharge: '充值',
    selectPlaceHolder: '请选择',
    changeMode: '轮换模式',
    randomIP: '随机IP',
    delayIP: '延时IP',
    delayDuration: 'IP持续时长（1-120分钟）',
    hostAndPort: '主机IP:端口',
    proxyUsername: '代理用户名',
    proxyPassword: '代理密码',
    tips: '由于政策原因，本站代理服务不支持在中国大陆使用。',
    proxyAddress1: '用户名:密码:主机IP:端口',
    proxyAddress2: '主机IP:端口:用户名:密码',
    proxyAddress3: '用户名:密码@主机IP:端口',
    addBatch: '批量生成',
    copyList: '复制代理列表',
    userPlaceHolder: '输入用户名',
    search: '查询',
    addAuthUser: '添加授权用户',
    username: '用户名',
    flowLimit: '流量使用上线/G',
    status: '状态',
    totalConsume: '总计消耗',
    createTime: '创建时间',
    password: '密码',
    usernamePlaceHolder: '支持6～24位字符，字母/数字/下划线',
    usernameEmpty: '请输入用户名',
    usernameError: '请输入6-24位字符的用户名',
    passwordPlaceHolder: '密码需要6～15字符，不支持特殊符号',
    passwordEmpty: '请输入密码',
    passwordError: '密码需要6-15字符，不支持特殊符号',
    flowLimitGB: '流量上限（GB）',
    orderDetail: '订单明细',
    startAt: '开始日期',
    endAt: '结束日期',
    orderNoPlaceHolder: '输入订单号',
    orderNo: '订单号',
    packageDuration: '套餐期限',
    flow: '流量',
    totalAmount: '总金额',
    orderStatus: '订单状态',
    paymentTime: '支付时间',
    actions: '操作',
    toPay: '去支付',
    proxyType: '代理类型',
    selectPackage: '选择套餐',
    purchaseNotice: '<p><strong> 购买须知</strong></p><p>1、本服务售卖的代理为固定期限套餐，过期后无法找回，请您留意续期时间。</p><p>2、代理售出后<span style="color: rgb(255, 0, 0);">不支持退换</span>，购买前请确认代理类型及地区避免误购。</p>',
    day: '天',
    payableAmount: '应付金额',
    flow: '流量/GB',
    payType: '支付方式',
    alipay: '支付宝',
    alipayPay: '支付宝支付',
    payAgreement: '支付即视为同意',
    agreement: '《服务协议》',
    agreementError: '请阅读并同意支付服务协议！',
    pendingPay: '待支付',
    finishPay: '已支付',
    cancelPay: '已关闭',
    avatar: '头像',
    email: '邮箱',
    nick: '昵称',
    toPay: '去支付'
  },
  payment: {
    orderInfo: '订单信息',
    orderNo: '订单编号',
    amount: '应付金额',
    exchangeAmount: '兑换金额',
    alipay: '支付宝',
    weixin: '微信',
    zhifubaopay: '支付宝支付',
    zhifubaotips: '请使用支付宝扫描\n二维码以完成支付',
    weixinpay: '微信支付',
    weixintips: '请使用微信扫描\n二维码以完成支付',
    paySuccess: '支付成功',
    payFailed: '支付失败',
    payResult: '支付结果',
    pleasePayIn: '请在{time}内完成扫码支付',
    timeout: '订单已过期',
    orderExpired: '订单已过期，请重新购买',
    error: '错误',
    qrcodeError: '获取支付二维码失败',
    createQrcodeError: '创建支付二维码失败',
    fetchOrderError: '获取订单信息失败',
    orderNotFound: '订单不存在或已过期',
    networkError: '网络连接失败，请检查网络后重试',
    qrcodeEmpty: '获取到的二维码为空',
    waitingPay: '待支付',
    paySuccess: '支付成功',
    payFailed: '支付失败',
    paySuccessTips: '订单支付成功，正在跳转...',
    payFailedTips: '支付失败，请重新购买',
    orderStatus: '订单状态',
    pendingPay: '待支付',
    finishPay: '已支付',
    cancelPay: '已关闭',
    scanToPay: '请使用手机扫一扫完成支付'
  },
  test: '测试'
}