<template>
    <section class="app-main">
        <transition name="fade-transform" mode="out-in">
            <keep-alive>
                <router-view :key="key" />
            </keep-alive>
        </transition>
    </section>
</template>

<script>
export default {
    name: 'AppMain',
    data() {
        return {
        }
    },
    computed: {
        key() {
            // console.log('route key:',this.$route.path)
            return this.$route.path
        }
    }
}
</script>

<style lang="scss" scoped>
    .app-main {
        min-height: calc(100vh - 429px);
        display: grid;
    }
</style>