import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import i18n from './lang'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/registration'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title ? i18n.t('route.' + to.meta.title) : '')

  // determine whether the user has logged in
  const hasToken = getToken()

  if (to.path === '/login' || to.path === '/registration') {
    // 如果是登录或注册页面，直接进入
    next()
    NProgress.done()
    return
  }

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasGetUserInfo = store.state.user.user
      if (hasGetUserInfo) {
        next()
      } else {
        try {
          await store.dispatch('user/getInfo')
          next()
        } catch (error) {
          await store.dispatch('user/resetToken')
          if (to.path !== '/login') {
            next({ path: '/login', replace: true })
          } else {
            next(false)
          }
          NProgress.done()
        }
      }
    }
  } else {
    if (!to.meta.auth) {
      next()
    } else {
      if (to.path !== '/login') {
        next({ path: '/login', replace: true })
      } else {
        next(false)
      }
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
