<template>
  <div id="app">
    <router-view/>
    <customer-service/>
  </div>
</template>

<script>
import CustomerService from '@/components/CustomerService.vue'

export default {
  name: 'App',
  components: {
    CustomerService
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100%;
}
</style>
