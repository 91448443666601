export default {
  route: {
    Login: '登錄',
    Registration: '註冊',
    ForgetPasswd: '忘記密碼',
    Home: '首頁',
    Price: '價格',
    Product: '產品',
    My: '全球安全可靠的IP代理服務商',
    Error: '全球安全可靠的IP代理服務商'
  },
  nav: {
    home: '首頁',
    price: '價格',
    product: '產品',
    resourceCenter: '資源中心',
    login: '登錄',
    registration: '註冊',
    introductionGuide: '入門指導',
    commonProblem: '常見問題',
    adsPowerAgentConfigurationTutorial: 'AdsPower代理配寘教程',
    adsPowerDynamicProxyConfigurationTutorial: 'AdsPower動態代理配寘教程',
    bitBrowserStaticProxyConfigurationTutorial: '比特瀏覽器靜態代理配寘教程',
    blog: '博客'
  },
  header: {
    language: {
      'zh-CN': '簡體中文',
      'zh-TW': '繁體中文',
      en: '英文'
    },
    switchLanguage: '切換語言'
  },
  footer: {
    contactus: '聯繫我們',
    email: '郵箱',
    phone: '電話',
    product: '產品',
    resourceService: '資源與服務',
    useCase: '使用案例',
    legalDisclaimer: '法律聲明',
    dynamicProxy: '動態真人住宅代理',
    ipv4: '資料中心IPv4代理',
    ipv6: '資料中心IPv6代理',
    staticISP: '靜態住宅代理',
    price: '價格',
    QA: 'Q&A',
    useCase: '使用案例',
    serviceClause: '服務與條款',
    lawAndPrivacy: '法律與隱私'
  },
  global: {
    title: '全球安全可靠的IP代理服務商',
    confirmLogout: '確認註銷',
    loginAgain: '重新登錄',
    cancel: '取消',
    error: '錯誤',
    learnMore: '了解更多',
    buyNow: '立即購買',
    confirm: '確定',
    cancelDone: '取消成功',
    edit: '修改'
  },
  user: {
    nickNameBlank: '暱稱不能為空！',
    passwordBlank: '密碼不能為空！',
  },
  auth: {
    loginBtn: '登錄',
    loginLink: '登錄',
    registrationBtn: '註冊',
    registrationLink: '立即註冊',
    loginTitle: '帳號登錄',
    registrationTitle: '帳號註冊',
    emailPlaceHolder: '請輸入郵箱',
    passwordPlaceHolder: '請輸入密碼',
    repasswordPlaceHolder: '請輸入確認密碼',
    rememberPassword: '記住密碼',
    forgetPassword: '忘記密碼',
    hasAccountTips: '已有帳號？',
    readAgreement: '我已閱讀並同意',
    userAgreement: '用戶服務協議',
    captchaPlaceHolder: '請輸入驗證碼',
    sendCaptchaLink: '獲取驗證碼',
    sendCaptchaAgain: '{second}秒後可重發',
    forgetPasswdTitle: '重置密碼',
    newpasswordPlaceHolder: '請輸入新密碼',
    confirm: '確認',
    sendCaptchSuccess: '驗證碼已發送至你的註冊郵箱',
    readAgreementConfirm: '請先閱讀並同意使用者服務協定'
  },
  home: {
    goodsTitle: '我們的產品',
    advantageTitle: '我們的優勢',
    partnerTitle: '合作夥伴',
  },
  product: {
    title: '我們的產品',
    dynamic: {
      iconClass: '',
      title: '動態真人住宅代理',
      description: '最真實的動態住宅代理，通過高頻次更換住宅IP地址來增强代理的穩定性與可信度，高安全性和隱蔽地繞過網絡限制和封鎖',
      advantageList: [
        {
          name: '隱匿性',
          description: '業內頂尖級別的安全隱匿性'
        },
        {
          name: '<div style="display:flex;flex-direction:row;align-items:flex-start;"><span style="font-size:14px">3000万</span><span style="font-size: 7px">+</span></div>',
          description: '合規的真人住宅IP代理網絡'
        },
        {
          name: '99.97%',
          description: '正常運營時間'
        },
        {
          name: '179個',
          description: '國家位置'
        },
        {
          name: '支持',
          description: 'HTTP(S) & SOCKS5協議'
        }
      ],
      introduction: {
        title: '什麼是動態住宅IP.',
        description: '動態住宅代理是通過無數真實住宅IP地址提供的代理服務，動態住宅代理會定期更換IP地址，模仿普通家庭用戶的上網行為，因而難以被目標網站檢測到為代理。',
        tips: '價格低至',
        buyNow: '立即選購',
        mainTitle: '主要特點',
        mainData: [
          {
            name: '動態性',
            description: 'IP地址會不斷輪換，新增隱蔽性和成功率'
          },
          {
            name: '真實住宅IP',
            description: '來自實際的ISP提供的IP，因而看起來像普通用戶的請求'
          },
          {
            name: '高匿名性',
            description: '難以被檢測和封鎖，適合繞過地理限制和訪問敏感資訊'
          }
        ]
      }
    },
    staticISP: {
      iconClass: '',
      title: '獨享靜態ISP代理',
      description: '從全球互聯網服務提供者（ISP）分配活躍IP地址，使用戶能够根據需要長期使用相同的住宅IP。 這種獨享靜態住宅代理不僅速度快且高度可靠，有效類比真實用戶的操作行為',
      advantageList: [
        {
          name: '真實用戶模擬',
          description: '更難被網站識別為代理，提高隱蔽性'
        },
        {
          name: '高匿名性',
          description: '减少了被監測和封鎖的風險'
        },
        {
          name: '低封鎖率',
          description: '使用IP時被網站封鎖的概率較低'
        },
        {
          name: '靈活性',
          description: '用戶可以根據需求長期使用相同的IP'
        },
        {
          name: '更好的相容性',
          description: '支持更多類型的網站和服務'
        }
      ],
      introduction: {
        title: '真實用戶模擬',
        description: '獨享ISP住宅代理使用的是實際家庭用戶的IP地址，更難被網站識別為代理，從而提高隱蔽性。',
        tips: '價格低至',
        buyNow: '立即選購',
        mainData: [
          {
            name: '高匿名性'
          },
          {
            name: '低封鎖率',
          },
          {
            name: '靈活性',
          },
          {
            name: '更好的兼容性',
          }
        ]
      }
    },
    static: {
      iconClass: '',
      title: '靜態代理',
      description: '靜態IPv4是由服務提供者（ISP）分配的固定IP地址，獨享ISP住宅代理利用這些靜態IP，確保速度的同時降低被監測和遮罩的風險讓用戶可以持續使用相同的住宅IP',
      advantageList: [
        {
          name: '穩定性',
          description: '始終使用相同的IP地址，確保連接的穩定性'
        },
        {
          name: '高匿名性',
          description: '較少被檢測和封鎖，適合需要隱私保護的操作'
        },
        {
          name: '快速速度',
          description: '適合數據抓取和其他高頻寬需求的任務'
        },
        {
          name: '可靠性',
          description: '避免了共亯代理帶來的速度下降或連接問題'
        },
        {
          name: '可控性',
          description: '用戶可以更好地管理和監控自己的IP使用情况'
        }
      ],
      introduction: {
        title: '靜態代理',
        description: '靜態IPv4是由服務提供者（ISP）分配的固定IP地址，獨享ISP住宅代理利用這些靜態IP，確保速度的同時降低被監測和遮罩的風險讓用戶可以持續使用相同的住宅IP',
        tips: '價格低至',
        buyNow: '立即選購',
        mainData: [
          {
            name: '穩定性',
            description: '始終使用相同的IP地址確保連接的穩定性'
          },
          {
            name: '高匿名性',
            description: '較少被檢測和封鎖，適合需要隱私保護的操作'
          },
          {
            name: '快速速度',
            description: '適合數據抓取和其他高頻寬需求的任務'
          },
          {
            name: '可靠性',
            description: '避免了共亯代理帶來的速度下降或連接問題'
          },
          {
            name: '可控性',
            description: '用戶可以更好地管理和監控自己的IP使用情况'
          }
        ]
      }
    }
  },
  advantage: {
    stability: '穩定性',
    fastspeed: '快速速度',
    highanonymity: '高匿名性',
    reliability: '可靠性',
    controllability: '可控性'
  },
  price: {
    title: '多樣化的價格',
    dynamic: '動態真人住宅代理',
    staticISP: '獨享靜態ISP代理',
    static: '靜態代理',
    outOfStock: '缺貨',
    dayPackage: '天套餐',
    advantage: [
      {
        name: '安全與隱私保障'
      },
      {
        name: '300+國家上千萬代理池'
      },
      {
        name: '卓越的網絡速度'
      },
      {
        name: '高度可擴展性'
      },
      {
        name: '99.9%正常運行時間'
      },
      {
        name: '智能流量管理'
      }
    ]
  },
  my: {
    buyProxy: '購買代理',
    dashboard: '儀表盤',
    proxyManagement: '代理管理',
    dynamic: '動態住宅代理',
    proxySetting: '代理設定',
    authUser: '授權用戶列表',
    myAccount: '我的帳戶',
    accountSetting: '帳號設定',
    logout: '退出',
    leftFlow: '剩餘流量',
    expirationDate: '到期時間',
    country: '國家',
    stateCityOrISP: '州-城市/ISP',
    stateCity: '州-城市',
    ISP: 'ISP',
    state: '州',
    city: '城市',
    authUser: '授權用戶',
    createAuthUser: '創建授權用戶',
    recharge: '充值',
    selectPlaceHolder: '請選擇',
    changeMode: '輪換模式',
    randomIP: '隨機IP',
    delayIP: '延時IP',
    delayDuration: 'IP持續時長（1-120分鐘）',
    hostAndPort: '主機IP:端口',
    proxyUsername: '代理用戶名',
    proxyPassword: '代理密碼',
    tips: '由於政策原因，本站代理服務不支持在中國大陸使用。',
    proxyAddress1: '用戶名：密碼：主機IP:端口',
    proxyAddress2: '主機IP:端口：用戶名：密碼',
    proxyAddress3: '主機IP:端口：用戶名：密碼',
    addBatch: '批量生成',
    copyList: '複製代理清單',
    userPlaceHolder: '輸入用戶名',
    search: '査詢',
    addAuthUser: '添加授權用戶',
    username: '用戶名',
    flowLimit: '流量使用上線/G',
    status: '狀態',
    totalConsume: '總計消耗',
    createTime: '創建時間',
    password: '密碼',
    usernamePlaceHolder: '支持6～24比特字元，字母/數位/底線',
    usernameEmpty: '請輸入用戶名',
    usernameError: '請輸入6-24比特字元的用戶名',
    passwordPlaceHolder: '密碼需要6～15字元，不支持特殊符號',
    passwordEmpty: '請輸入密碼',
    passwordError: '密碼需要6-15字元，不支持特殊符號',
    flowLimitGB: '流量上限（GB）',
    orderDetail: '訂單明細',
    startAt: '開始日期',
    endAt: '結束日期',
    orderNoPlaceHolder: '輸入訂單號',
    orderNo: '訂單號',
    packageDuration: '套餐期限',
    flow: '流量',
    totalAmount: '總金額',
    orderStatus: '訂單狀態',
    paymentTime: '支付時間',
    actions: '操作',
    toPay: '去支付',
    proxyType: '代理類型',
    selectPackage: '選擇套餐',
    purchaseNotice: '<p><strong> 购买须知</strong></p><p>1、本服务售卖的代理为固定期限套餐，过期后无法找回，请您留意续期时间。</p><p>2、代理售出后<span style="color: rgb(255, 0, 0);">不支持退换</span>，购买前请确认代理类型及地区避免误购。</p>',
    day: '天',
    payableAmount: '應付金額',
    flow: '流量/GB',
    payType: '支付方式',
    alipay: '支付寶',
    alipayPay: '支付寶支付',
    payAgreement: '支付即視為同意',
    agreement: '《服務協定》',
    agreementError: '請閱讀並同意支付服務協定！',
    pendingPay: '待支付',
    finishPay: '已支付',
    cancelPay: '已關閉',
    avatar: '頭像',
    email: '郵箱',
    nick: '昵稱'
  },
  test: '测试'
}